import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useDateRanges } from '../../../../shared/hooks/UseDateRanges'
import { IAdvertiserReportFilter } from '../interfaces/IAdvertiserReportFilter'
import { RootState } from '../../../../store/Store'
import { IPagination } from '../../../../shared/interfaces/Pagination.interface'

type AdvertiserReportState = {
  filters: IAdvertiserReportFilter
  pagination: IPagination
  allowDecloak: boolean
}

const dateRanges = useDateRanges()

const advertiserReportSlice = createSlice({
  name: 'research',
  initialState: {
    filters: {
      dateRange: dateRanges.month,
      show_hidden: false,
      is_decloak: false,
      policy: '',
      status: '',
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 1,
    },
    allowDecloak: false,
  } as AdvertiserReportState,
  reducers: {
    setAdvertiserReportFilter: (
      state: AdvertiserReportState,
      action: PayloadAction<IAdvertiserReportFilter>,
    ) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    setAdvertiserReportPagination: (
      state: AdvertiserReportState,
      action: PayloadAction<IPagination>,
    ) => {
      state.pagination = action.payload
    },
    setAllowDecloak: (state: AdvertiserReportState, action: PayloadAction<boolean>) => {
      state.allowDecloak = action.payload
    },
  },
})

export const { setAdvertiserReportFilter, setAdvertiserReportPagination, setAllowDecloak } =
  advertiserReportSlice.actions

export const selectAdvertiserReportFilters = (state: RootState) =>
  state.root.advertiserReport.filters
export const selectAdvertiserReportPagination = (state: RootState) =>
  state.root.advertiserReport.pagination
export const selectAllowDecloak = (state: RootState) => state.root.advertiserReport.allowDecloak

export default advertiserReportSlice.reducer
